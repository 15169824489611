import React from "react";
import PropTypes from "prop-types";
import SEO from "../components/functional/seo";
import DesignStory from "../components/molecules/design-story";
import MediaBlock from "../components/organisms/media-block";
import MediaCarousel from "../components/organisms/media-carousel";

function DesignCardCarousel({ props }) {
  const designProps = props;
  return (
    <div className="design__content design__content--media">
      <SEO title="Design System - Card Carousel" />
      <h2>Card Carousel</h2>
      <div className="design__stories">
        <DesignStory
          title="Card Carousel"
          component={MediaCarousel}
          subComponent={MediaBlock}
          type="carousel"
          props={designProps}
        />
      </div>
    </div>
  );
}

DesignCardCarousel.propTypes = {
  props: PropTypes.objectOf(PropTypes.any),
};

DesignCardCarousel.defaultProps = {
  props: {},
};

export default DesignCardCarousel;
